import React from 'react';
import styled from 'styled-components';
import { MdPause, MdPlayArrow } from 'react-icons/md';

import { AudioPlayerContext } from '../components/AudioPlayerContext';
import SEO from '../components/seo';
import audios from '../audios';
import { Button, Page } from '../components/styled';

const Record = styled.div`
    padding-top: 24px;
    padding-bottom: 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    position: relative;
`;

const Progress = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    max-width: 400px;
    height: 4px;
    background: ${(props) => `
        linear-gradient(to right, 
                #222222 ${props.progress * 100}%, 
                #cccccc ${props.progress * 100}%
            )
        `};
`;

/* function formatDuration(duration) {
    duration = Math.floor(duration);

    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;

    return `${minutes}:${String(seconds).padStart(2, '0')}`;
} */

const RecordingsPage = () => (
    <AudioPlayerContext.Consumer>
        {({ state, dispatch }) => {
            return (
                <Page>
                    <SEO title="Recordings" pathname="/recordings" />
                    <h1>Recordings</h1>
                    {audios.map((audio, index) => {
                        return (
                            <Record key={index}>
                                {(state.playing || !!state.currentTime) &&
                                    state.audioIndex === index && (
                                        <Progress
                                            progress={
                                                state.currentTime /
                                                state.duration
                                            }
                                        />
                                    )}
                                {state.playing && state.audioIndex === index && (
                                    <Button
                                        onClick={() =>
                                            dispatch({ type: 'pauseClick' })
                                        }
                                        aria-label="Pause track"
                                    >
                                        <MdPause size="32px" />
                                    </Button>
                                )}
                                {!(
                                    state.playing && state.audioIndex === index
                                ) && (
                                    <Button
                                        onClick={() =>
                                            dispatch(
                                                state.audioIndex === index
                                                    ? { type: 'playClick' }
                                                    : {
                                                          type: 'setIndex',
                                                          index,
                                                      }
                                            )
                                        }
                                        aria-label="Play track"
                                    >
                                        <MdPlayArrow size="32px" />
                                    </Button>
                                )}
                                <div style={{ marginLeft: '16px' }}>
                                    {audio.author}
                                    <br />
                                    {audio.name}
                                </div>
                            </Record>
                        );
                    })}
                </Page>
            );
        }}
    </AudioPlayerContext.Consumer>
);

export default RecordingsPage;
